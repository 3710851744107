export const GUL_LAPSE_PROTECTION_TO_AGES = [120, 100, 95, 90, 85];
export const ACCIDENTAL_DEATH_RATES = [
  50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000,
];
// numbers from 1,500 to 15,000, incremented by 500
export const MONTHLY_BENEFITS = new Array((15000 - 3000) / 500 + 1)
  .fill(0)
  .map((v, index) => 3000 + index * 500);
export const MONTHLY_BENEFIT_MOST_COMMON = 5000;
// numbers from 75,000 to 500,000, incremented by 5,000

export const UNLIMITED_MONEY_POOL = -1;
const itemLength = (500000 - 75000) / 5000 + 1;
export const MONEY_POOL_OPTIONS = [
  ...new Array(itemLength).fill(0).map((v, index) => 75000 + index * 5000),
  UNLIMITED_MONEY_POOL,
];

export const ELIMINATION_PERIODS = [0, 30, 60, 90, 180, 365];
export const ELIMINATION_PERIOD_MOST_COMMON = 90;
export const BOOLEAN_OPTIONS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const WITHOUT_CHRONIC_LTC = 'Without Chronic Illness/LTC Rider';
export const WITH_LTC_RIDER = 'With LTC Rider';
export const WITH_CHRONIC_ILLNESS_LTC_RIDER = 'With Chronic Illness/LTC Rider';
export const WITH_FULL_CHRONIC_ILLNESS_LTC_RIDER = 'With Full Chronic Illness/LTC Rider';
export const WITH_CHRONIC_ILLNESS_RIDER = 'With Chronic Illness Rider';
export const RIDER_OPTIONS = [
  WITH_CHRONIC_ILLNESS_LTC_RIDER,
  WITH_FULL_CHRONIC_ILLNESS_LTC_RIDER,
  WITH_CHRONIC_ILLNESS_RIDER,
  WITH_LTC_RIDER,
  WITHOUT_CHRONIC_LTC,
];

export const JOINT_INSURED_PARTNER_DISCOUNT = 'both';
export const SPOUSE_PARTNER_DISCOUNT = 'one';

export const PARTNER_DISCOUNT_OPTIONS = [
  { text: 'Single', value: null },
  {
    text: 'Married, Applying with Spouse',
    value: JOINT_INSURED_PARTNER_DISCOUNT,
  },
  { text: 'Married, Applying Individually', value: SPOUSE_PARTNER_DISCOUNT },
];

export const RIDER_PERCENTAGE_OPTIONS = [1, 2, 3, 4];

export const INCOME_SOLVE_OPTIONS = ['No', 'Max'];

export const MAX_INCOME_AGE = 121;

export const MIN_COL_SIZE = 4;

export const VITALITY_LEVELS = [
  {
    text: 'None',
    value: null,
    color: 'vitality-none',
    matches: (v) => !v,
  },
  {
    text: 'Bronze',
    value: 'Bronze',
    color: 'vitality-bronze',
    matches: (v) => 'Bronze' === v,
  },
  {
    text: 'Silver',
    value: 'Silver',
    color: 'vitality-silver',
    matches: (v) => 'Silver' === v,
  },
  {
    text: 'Gold',
    value: 'Gold',
    color: 'vitality-gold',
    matches: (v) => 'Gold' === v,
  },
  {
    text: 'Platinum',
    value: 'Platinum',
    color: 'vitality-platinum',
    matches: (v) => 'Platinum' === v,
  },
];

export const UNLIMITED_BENEFIT_PERIOD = -1;

export const DISCOUNT_OPTIONS = [
  { text: 'None', value: null },
  { text: 'Association', value: 'Association' },
  { text: 'Employer', value: 'Employer' },
  { text: 'Producer', value: 'Producer' },
];

export const CREDITING_RATES = [
  {
    value: 'Default Rate',
    text: 'Default Rate',
  },
  {
    value: '10% Reduction',
    text: '10% Reduction',
  },
  {
    value: '20% Reduction',
    text: '20% Reduction',
  },
  {
    value: '30% Reduction',
    text: '30% Reduction',
  },
  {
    value: '40% Reduction',
    text: '40% Reduction',
  },
  {
    value: '50% Reduction',
    text: '50% Reduction',
  },
];

export const LOAN_TYPES = [
  { text: 'Fixed', value: 'Fixed' },
  { text: 'Participating', value: 'Variable' },
];
