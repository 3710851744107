import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { PRODUCTS, categoryToConstantProduct, CATEGORIES } from '#src/structures/ProductType.js';
import { defineStore } from "#src/stores/state-wrapper.js"
import { integerToPercent, timestampFormatter } from '#src/util/helpers.js';

import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { getPartyStore, useEappStore } from '#src/stores/electronic-application.js';
import { usePrimaryInsuredStore, useJointInsuredStore } from '#src/stores/insured.js';
import { useQuotingStore } from '#src/stores/quoting.js';
import { useCaseStore } from '#src/stores/case.js';

export const useStepCompletedStore = (pinia, hot) =>
  defineStore('step-completed', {
    state: () => ({
      // initial complete context data
      showSigningByEmail: false,
      showCompletedContext: false,

      // case/eapp context data
      signerStatus: null,
      caseId: null,
      caseUuid: null,
      resumeCarrierEapp: null,
      resumeCarrierEappText: null,

      premium: null,
      amount: null,
      status: null,
      mode: null,
      line: null,
      policyNumber: 'Pending',
      state: null,
      sharableLink: null,

      inforceDate: null,
      appliedRating: null,
      approvedRating: null,
      appliedPremium: null,
      approvedPremium: null,

      carrier: {
        avatarUrl: null,
        name: null,
      },

      product: {
        name: null,
      },

      primaryAgent: {
        name: null,
        email: null,
        phone: null,
        avatarUrl: null,
        type: null,
        schedulingLink: null,
      },

      requirements: [],
      contractParties: [],
    }),
    getters: {
      step: () => steps.COMPLETED,
      routeName: (s) => stepToRouteName[s.step],
      next: () => null,
      previous: () => null,
      hidesFooter: () => true,
      hidesHeader: () => true,
      hidesShare: () => true,
      skipStepSave() {
        const caseStore = useCaseStore(pinia);
        return Boolean(caseStore.id) || this.showSigningByEmail ;
      },
    },
    actions: {
      setContext() {
        const showSigningByEmail = this.showSigningByEmail;
        const showCompletedContext = this.showCompletedContext;
        this.$reset();
        this.showSigningByEmail = showSigningByEmail;
        this.showCompletedContext = showCompletedContext;

        const caseStore = useCaseStore(pinia);
        if (caseStore.id) this.useCaseContext();
        else this.useEappContext();
      },
      useCaseContext() {
        const instance = useInstanceSettingsStore(pinia);
        const caseStore = useCaseStore(pinia);
        this.caseId = caseStore.id;
        this.caseUuid = caseStore.uuid;
        this.status = caseStore.status;
        this.premium = caseStore.premium;
        this.mode = caseStore.mode;
        this.policyNumber = caseStore.policy_number;
        this.state = caseStore.state;
        this.appliedRating = caseStore.applied_rating;
        this.approvedRating = caseStore.approved_rating;
        this.appliedPremium = caseStore.applied_premium;
        this.approvedPremium = caseStore.approved_premium;
        this.line = caseStore.line;

        let amount = caseStore.face_amount;
        if (this.line === 'ltc') amount = caseStore.pool_of_money;
        this.amount = amount;

        this.product.name = caseStore.product.name;

        this.carrier.avatarUrl = caseStore.carrier.avatar_url;
        this.carrier.name = caseStore.carrier.name;

        this.primaryAgent.name = caseStore.primary_agent.name;
        this.primaryAgent.phone = caseStore.primary_agent.phone_work;
        this.primaryAgent.email = caseStore.primary_agent.email;
        this.primaryAgent.type = caseStore.primary_agent.type;
        this.primaryAgent.avatarUrl = instance.logo;
        if (caseStore.primary_agent.scheduling_link) {
          const link = caseStore.primary_agent.scheduling_link
            .replace('https://', '')
            .replace('http://', '');
          this.primaryAgent.schedulingLink = `https://${link}`;
        }

        this.inforceDate = caseStore.inforce_date;

        this.requirements.push(...caseStore.requirements);

        caseStore.contract_parties.forEach((party) => {
          this.contractParties.push({
            name: party.name,
            roles: party.roles.map((r) => ({
              role: r.role,
              beneficiaryAmount: r.beneficiary_amount,
            })),
          });
        });

        if (caseStore.sharable_apply_link) {
          try {
            const url = new URL(caseStore.sharable_apply_link);
            url.searchParams.append('prefill', true);
            url.searchParams.append(
              'metadata',
              JSON.stringify({
                eapp_id: caseStore.eapp_id,
                case_id: caseStore.id,
              }),
            );

            this.sharableLink = url.toString();
          } catch (e) {
            // do nothing
          }
        }
      },
      useEappContext() {
        const eApp = useEappStore(pinia);
        const quoting = useQuotingStore(pinia);
        const insured = usePrimaryInsuredStore(pinia);
        const instance = useInstanceSettingsStore(pinia);
        this.status = 'Pending';

        this.signerStatus = eApp.signer_status;
        this.state = quoting.params.state;

        this.primaryAgent.name = instance.domainContacts[0].title;
        this.primaryAgent.type = instance.domainContacts[0].subtitle;
        this.primaryAgent.email = instance.domainContacts[0].email;
        this.primaryAgent.phone = instance.phone;
        this.primaryAgent.avatarUrl = instance.logo;
        if (instance.scheduling_link) {
          const link = instance.scheduling_link.replace('https://', '').replace('http://', '');
          this.primaryAgent.schedulingLink = `https://${link}`;
        }

        this.premium = eApp.selectedQuote.premium;
        this.mode = eApp.selectedQuote.mode;
        this.carrier.avatarUrl = eApp.selectedQuote.carrier.avatar_url;
        this.carrier.name = eApp.selectedQuote.carrier.name;

        this.product.name = eApp.selectedQuote.product.name;
        const categories = categoryToConstantProduct(eApp.selectedQuote.product.category);

        let line = 'life';
        let amount = eApp.selectedQuote.initial_death_benefit || eApp.selectedQuote.face_amount;
        if (categories.includes(PRODUCTS.LTC)) {
          line = 'ltc';
          amount = eApp.selectedQuote.pool_of_money;
        }

        this.line = line;
        this.amount = amount;

        this.resumeCarrierEapp = eApp.resume_carrier_eapp;
        if (eApp.limit_carrier_eapp_to_insured) {
          this.resumeCarrierEappText = `${insured.name} must finish this application on ${this.carrier.name}'s website`;
        } else if (eApp.resume_carrier_eapp) {
          this.resumeCarrierEappText = `${this.carrier.name}'s Application requires additional information`;
        }

        this.requirements = [];

        if (this.resumeCarrierEapp) {
          // no tasks, the continue eapp banner is visible
        } else if (this.signerStatus && !this.showCompletedContext) {
          this.requirements.push(this.signerStatus);
        } else {
          let examText = '';
          if (insured.exam_date) {
            try {
              const formattedDate = timestampFormatter(
                new Date(insured.exam_date),
                'none',
                'localized-full-date-time',
              );
              examText = `${insured.name} will need to complete their exam on ${formattedDate}.`;
            } catch (e) {
              // error parsing the exam date
            }
          }

          const categories = eApp.selectedQuote.product.category.split(".")
          const isGuaranteedIssue = categories.includes(CATEGORIES.GUARANTEED_ISSUE)

          if (this.showSigningByEmail) {
            this.requirements.push(
              `The next step is to have ${insured.name} open their email to eSign their application using the last four digits of their SSN as their access code.`,
            );
            if (examText) this.requirements.push(`After ${insured.name} has signed, ${examText}`);
          } else if (isGuaranteedIssue && examText) {
            if (examText) this.requirements.push(examText);
            this.requirements.push(
              `After ${insured.name}'s exam is completed, the policy will arrive in the mail in the next 10 days.`,
            );
          } else if (isGuaranteedIssue) {
            this.requirements.push(
              `${insured.name}'s policy will arrive in the mail in the next 10 days.`,
            );
          } else {
            this.requirements.push(
              `${insured.name}'s application is being sent to ${this.carrier.name}.`,
            );
            if (examText) this.requirements.push(examText);
          }
        }

        if (eApp.sharable_apply_link) {
          try {
            const url = new URL(eApp.sharable_apply_link);

            url.searchParams.append('prefill', true);
            url.searchParams.append('metadata', JSON.stringify({ eapp_id: eApp.id }));

            this.sharableLink = url.toString();
          } catch (e) {
            // do nothing
          }
        }

        const roleMapper = (v) => {
          const roles = [];
          Object.keys(v.roles).forEach((role) => {
            if (!v.roles[role]) return;
            if (role.indexOf('Beneficiary') === -1) roles.push({ role });
            else
              roles.push({
                role,
                beneficiaryAmount: integerToPercent(v.beneficiary_amount.model),
              });
          });
          return roles;
        };

        this.contractParties.push({
          name: insured.displayName,
          roles: roleMapper(insured),
        });

        const jointInsured = useJointInsuredStore(pinia);
        if (jointInsured.id) {
          this.contractParties.push({
            name: jointInsured.displayName,
            roles: roleMapper(jointInsured),
          });
        }

        eApp.parties.forEach((partyKey) => {
          const party = getPartyStore(partyKey, pinia);
          this.contractParties.push({
            name: party.displayName,
            roles: roleMapper(party),
          });
        });
      },
    },
  })(pinia, hot);
