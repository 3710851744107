import { defineStore } from "#src/stores/state-wrapper.js"
import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';

import { useStepSignatureWaitingStore } from '#src/stores/step-signature-waiting.js';
import { useStepCompletedStore } from '#src/stores/step-completed.js';

export const useStepSignatureStore = (pinia, hot) =>
  defineStore('step-signature', {
    state: () => ({
      isFullscreen: false,
    }),
    getters: {
      step: () => steps.SIGNATURE,
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const signatureWaiting = useStepSignatureWaitingStore(pinia);
        return signatureWaiting.step;
      },
      next() {
        const completed = useStepCompletedStore(pinia);
        return completed.step;
      },
      controlsNext: () => true,
      hidesFooter: () => true,
      hidesShare: () => true,
    },
  })(pinia, hot);
