export const VISA_OPTIONS = [
  "A-2 NATO1-6",
  "A",
  "B-1",
  "B-2",
  "BCC",
  "C",
  "C5, T5, R5, I5",
  "CW1",
  "D",
  "DV",
  "E-3",
  "E",
  "E1",
  "E2",
  "EW3",
  "F, M",
  "F2A, F2B",
  "G1-G5, NATO",
  "Green Card",
  "H-1B",
  "H-1B1",
  "H-2A",
  "H-2B",
  "H-3",
  "H-4",
  "I",
  "IR1, CR1",
  "IR2, CR2, IR5, F1, F3, F4",
  "IR3, IH3, IR4, IH4",
  "IR6",
  "J",
  "K-1",
  "K-3",
  "L",
  "No Visa",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "SB",
  "SD, SR",
  "SI",
  "SQ",
  "T",
  "TN/TD",
  "U",
  "V",
];
