import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';
import { SRQ } from '#src/enumerations/step-requirements.js';

import { useEappStore } from '#src/stores/electronic-application.js';
import { usePrimaryInsuredStore, useJointInsuredStore } from '#src/stores/insured.js';
import { useStepSmokerStore } from '#src/stores/step-smoker.js';
import { useStepBirthdateStore } from '#src/stores/step-birthdate.js';

import { useBuildForm } from '#src/components/steps/build/build-form.composable.js';

export const useStepBuildStore = (pinia, hot) =>
  defineStore('step-build', {
    state: () => ({ inactive: false }),
    getters: {
      step: () => steps.BUILD,
      routeName: (s) => stepToRouteName[s.step],
      next: () => {
        const birthdate = useStepBirthdateStore(pinia);
        return birthdate.step;
      },
      previous: () => {
        const smoker = useStepSmokerStore(pinia);
        if (smoker.inactive) return smoker.previous;
        return smoker.step;
      },
      skippable: () => {
        const eApp = useEappStore(pinia);
        const insured = usePrimaryInsuredStore(pinia);
        const jointInsured = useJointInsuredStore(pinia);
        if (eApp.isDualInsured) {
          return !insured.weight.model && !jointInsured.weight.model;
        }

        return !insured.weight.model;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.inactive = !srq[SRQ.BUILD];
      },
      generateForm(module, args) {
        return useBuildForm(pinia, module, args);
      },
    },
  })(pinia, hot);
