import AccidentalDeath from '#src/structures/quote-params/AccidentalDeath.js';
import FinalExpense from '#src/structures/quote-params/FinalExpense.js';
import {
  AccumulationIUL,
  AllUniversalLife,
  ProtectionIUL,
  ProtectionVUL,
  UniversalLife,
} from '#src/structures/quote-params/UniversalLife.js';
import LinkedBenefit from '#src/structures/quote-params/LinkedBenefit.js';
import LongTermCare from '#src/structures/quote-params/LongTermCare.js';
import ReturnOfPremium from '#src/structures/quote-params/ReturnOfPremium.js';
import { Term, NonMedQuoteParams } from '#src/structures/quote-params/Term.js';
import WholeLife from '#src/structures/quote-params/WholeLife.js';

export const PRODUCTS = {
  ACCIDENTAL_DEATH: 'accidentalDeath',
  FINAL_EXPENSE: 'fex',
  LTC: 'ltc',
  NON_MED: 'non_med',
  ACCUMULATION_IUL: 'aiul',
  PROTECTION_IUL: 'piul',
  PROTECTION_VUL: 'pvul',
  UNIVERSAL_LIFE: 'ul',
  ROP_TERM: 'ropTerm',
  TERM: 'term',
  WHOLE_LIFE: 'wholeLife',
  LINKED_BENEFIT: 'linkedBenefit',
};

export const CATEGORIES = {
  GUARANTEED_ISSUE: 'guaranteed_issue',
  GUARANTEED_ISSUE_FOR_BUILD: 'guaranteed_issue_for_build',
}

export const UL_TYPES = [
  PRODUCTS.ACCUMULATION_IUL,
  PRODUCTS.PROTECTION_IUL,
  PRODUCTS.PROTECTION_VUL,
  PRODUCTS.UNIVERSAL_LIFE,
];

export const CONCEPTS = {
  ALL_UL: 'allUl',
  TERM: 'term',
};

export function categoryToConstantProduct(str) {
  if (!str?.indexOf) return null;
  const rawCategory = str.split('.');
  const [base] = rawCategory;
  const categories = [];
  if (base === 'whole_life') {
    if (rawCategory.includes('none')) {
      categories.push(PRODUCTS.WHOLE_LIFE);
    }
    if (rawCategory.includes('final_expense')) {
      categories.push(PRODUCTS.FINAL_EXPENSE);
    }
  }

  if (base === 'universal_life') {
    if (rawCategory.includes('variable')) {
      categories.push(PRODUCTS.PROTECTION_VUL);
    }
    if (rawCategory.includes('accumulation')) {
      categories.push(PRODUCTS.ACCUMULATION_IUL);
    }
    if (rawCategory.includes('protection')) {
      categories.push(PRODUCTS.PROTECTION_IUL);
    }
    if (rawCategory.includes('none')) {
      categories.push(PRODUCTS.UNIVERSAL_LIFE);
    }
    if (rawCategory.includes('linked_benefit')) {
      categories.push(PRODUCTS.LINKED_BENEFIT);
    }
  }

  if (base === 'term') {
    if (rawCategory.includes('none')) {
      categories.push(PRODUCTS.TERM);
    }
    if (rawCategory.includes('return_of_premium')) {
      categories.push(PRODUCTS.ROP_TERM);
    }
    if (rawCategory.includes('non_med')) {
      categories.push(PRODUCTS.NON_MED);
    }
    if (rawCategory.includes('ul_term')) {
      categories.push(PRODUCTS.UNIVERSAL_LIFE);
    }
  }

  if (base === 'accidental_death') {
    if (rawCategory.includes('accidental_death')) {
      categories.push(PRODUCTS.ACCIDENTAL_DEATH);
    }
  }

  if (base === 'ltc') {
    categories.push(PRODUCTS.LTC);
  }

  return categories;
}

export function ProductType(type) {
  return {
    [PRODUCTS.FINAL_EXPENSE]: FinalExpense(),
    [PRODUCTS.ACCIDENTAL_DEATH]: AccidentalDeath(),
    [PRODUCTS.ACCUMULATION_IUL]: AccumulationIUL(),
    [PRODUCTS.PROTECTION_VUL]: ProtectionVUL(),
    [PRODUCTS.PROTECTION_IUL]: ProtectionIUL(),
    [PRODUCTS.UNIVERSAL_LIFE]: UniversalLife(),
    [PRODUCTS.LTC]: LongTermCare(),
    [PRODUCTS.ROP_TERM]: ReturnOfPremium(),
    [PRODUCTS.TERM]: Term(),
    [PRODUCTS.NON_MED]: NonMedQuoteParams(),
    [PRODUCTS.WHOLE_LIFE]: WholeLife(),
    [PRODUCTS.LINKED_BENEFIT]: LinkedBenefit(),
    [CONCEPTS.ALL_UL]: AllUniversalLife(),
  }[type];
}
