import { useStepBeneficiaryContingentStore } from '#src/stores/step-beneficiary-contingent.js';
import { useStepOwnerStore } from '#src/stores/step-owner.js';

import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"
import { SRQ } from '#src/enumerations/step-requirements.js';

export const useStepBeneficiaryPrimaryStore = (pinia, hot) =>
  defineStore('step-beneficiary-primary', {
    state: () => ({
      inactive: false,
      requiredFields: {
        address: false,
        phone: false,
        tin: false,
        gender: false,
        formationDate: false,
        relationship: false,
        birthdate: false,
        ssn: false,
        birthdateOrSsn: false,
        ssnOptional: false,
        revocable: false,
      },
    }),
    getters: {
      step: () => steps.PRIMARY_BENEFICIARY,
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const owner = useStepOwnerStore(pinia);
        if (owner.inactive) return owner.previous;
        return owner.step;
      },
      next() {
        const contingent = useStepBeneficiaryContingentStore(pinia);
        if (contingent.inactive) return contingent.next;
        return contingent.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.requiredFields.address = srq[SRQ.BENEFICIARY_ADDRESS];
        this.requiredFields.phone = srq[SRQ.BENEFICIARY_PHONE];
        this.requiredFields.tin = srq[SRQ.BENEFICIARY_TIN];
        this.requiredFields.formationDate = srq[SRQ.BENEFICIARY_FORMATION_DATE];
        this.requiredFields.birthdateOrSsn = srq[SRQ.BENEFICIARY_BIRTHDATE_OR_SSN];
        this.requiredFields.relationship = srq[SRQ.BENEFICIARY_RELATIONSHIP];
        this.requiredFields.birthdate = srq[SRQ.BENEFICIARY_BIRTHDATE];
        this.requiredFields.ssn = srq[SRQ.BENEFICIARY_SSN];
        this.requiredFields.ssnOptional = srq[SRQ.BENEFICIARY_SSN_OPTIONAL];
        this.requiredFields.gender = srq[SRQ.BENEFICIARY_GENDER];
        this.requiredFields.revocable = srq[SRQ.BENEFICIARY_REVOCABLE];

        this.inactive = Object.values(this.requiredFields).every((v) => v === false);
      },
    },
  })(pinia, hot);
