import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"

import { useFlowStore } from '#src/stores/flow.js';
import { useStepBirthdateStore } from '#src/stores/step-birthdate.js';
import { useStepQuoteStore } from '#src/stores/step-quote.js';

export const useStepCompareStore = (pinia, hot) =>
  defineStore('step-compare', {
    getters: {
      step: () => steps.COMPARE,
      routeName: (s) => stepToRouteName[s.step],
      nextText: () => 'Next - View Carriers',
      previous() {
        const birthdate = useStepBirthdateStore(pinia);
        if (birthdate.next === this.step) return birthdate.step;
        return steps.LIFE_INSURANCE_PRIORITY;
      },
      next: () => {
        const quote = useStepQuoteStore(pinia);
        return quote.step;
      },
      inactive() {
        const flow = useFlowStore(pinia);
        return flow.onlyAccidentalDeath || flow.onlyOnePermanentProduct;
      },
    },
  })(pinia, hot);
