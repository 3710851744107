import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"
import { useSavableProperty } from '#src/composables/savable-property.composable.js';
import { SRQ } from '#src/enumerations/step-requirements.js';
import { isBoolean } from '#src/util/helpers.js';

import { INSURED_TYPES } from '#src/stores/insured.js';
import { useStepEmployerStore } from '#src/stores/step-employer.js';
import { useStepContactStore } from '#src/stores/step-contact.js';

export const useStepAddressStore = (pinia, hot) =>
  defineStore('step-address', {
    // can move these to their respective forms and use in component savable property?
    // Unease ~ removes from API-ability?
    state: () => ({
      insured_continuing_with_state: insured_continuing_with_state(INSURED_TYPES.INSURED, pinia),
      'joint-insured_continuing_with_state': insured_continuing_with_state(
        INSURED_TYPES.JOINT,
        pinia,
      ),
      synchronize: useSavableProperty({
        rules: {
          isValid: {
            v: () => {
              const store = useStepAddressStore(pinia);
              return isBoolean(store.synchronize.model);
            },
            message: 'Required',
          },
        },
      }),
      requiredFields: {
        yearsAtAddress: false,
      },
    }),
    getters: {
      step: () => steps.ADDRESS,
      routeName: (s) => stepToRouteName[s.step],
      previous: () => {
        const contact = useStepContactStore(pinia);
        return contact.step;
      },
      next() {
        const employer = useStepEmployerStore(pinia);
        if (employer.inactive) return employer.next;
        return employer.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.requiredFields.yearsAtAddress = srq[SRQ.INSURED_ADDRESS_START_DATE];
      },
    },
  })(pinia, hot);

const insured_continuing_with_state = (type, pinia) =>
  useSavableProperty({
    rules: {
      isValid: {
        v: () => {
          const store = useStepAddressStore(pinia);
          return ['switch', 'notify', 'continue'].includes(
            store[`${type}_continuing_with_state`].model,
          );
        },
        message: 'Required',
      },
    },
  });
