<template>
  <v-row class="ma-0 h-100 white" justify="center" align="center">
    <v-card flat>
      <v-card-title data-testid="error-message">
        {{ title }}
      </v-card-title>
      <v-card-subtitle
        v-if="isBadDomain || requiresSubscription || !noProducts"
        class="full-opacity"
        data-testid="error-subtitle"
      >
        <span v-if="isBadDomain">
          The Quote & Apply website you are trying to access is either deleted or does not exist.
          <br />
          Please go to
          <a
            href="https://app.back9ins.com/quote-and-apply"
            target="_blank"
            class="text-primary clickable"
          >
            https://app.back9ins.com/quote-and-apply
          </a>
          to verify this Quote & Apply website exists.
        </span>
        <span v-else-if="requiresSubscription">
          Embedding Quote & Apply requires a subscription. <br />
          Please go to
          <a href="https://app.back9ins.com/settings?page=subscriptions" target="_blank">
            https://app.back9ins.com/settings?page=subscriptions
          </a>
          to subscribe.
        </span>
        <span v-else-if="!noProducts">
          We are aware of the issue and it will be resolved shortly
        </span>
      </v-card-subtitle>
      <v-card
        height="25rem"
        width="35rem"
        class="my-6 overflow-hidden"
        :color="isBadDomain ? null : '#525252'"
      >
        <div class="window-container">
          <div class="window-icon bg-red" />
          <div class="window-icon bg-orange" />
          <div class="window-icon bg-green" />
        </div>

        <v-row
          v-if="isBadDomain"
          class="pa-0 ma-0 h-100"
          justify="center"
          align="center"
          data-testid="bad-domain-img"
        >
          <div style="width: 75%">
            <v-icon icon="custom:BadDomain" style="width: 27rem; height: 19rem" />
          </div>
        </v-row>
        <div v-else data-testid="default-img">
          <div class="connection-container">
            <div class="connection-container-accent" />
          </div>
          <v-icon icon="custom:ConnectionIssue" style="width: 35rem; height: 25rem" />
        </div>
      </v-card>
    </v-card>
  </v-row>
</template>

<script>
import { CRITICAL_FAILURE_REASONS, useStepCriticalFailure } from '#src/stores/step-critical-failure';
import { mapState } from 'pinia';

export default {
  name: 'CriticalFailure',
  computed: {
    ...mapState(useStepCriticalFailure, ['criticalFailureReason']),
    isBadDomain() {
      return this.criticalFailureReason === CRITICAL_FAILURE_REASONS.BAD_DOMAIN;
    },
    noProducts() {
      return this.criticalFailureReason === CRITICAL_FAILURE_REASONS.NO_PRODUCTS;
    },
    requiresSubscription() {
      return this.criticalFailureReason === CRITICAL_FAILURE_REASONS.REQUIRES_SUBSCRIPTION;
    },
    title() {
      if (this.isBadDomain || this.requiresSubscription) {
        return 'Unable to start Quote & Apply';
      } else if (this.noProducts) {
        return 'No Quote & Apply Products Detected';
      } else {
        return "We're sorry, Quote & Apply is not working correctly";
      }
    },
  },
};
</script>

<style lang="scss">
.window-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 5px;
}
.window-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  display: flex;
  z-index: 3;
}
.connection-container {
  position: absolute;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  transform: rotate(-28deg);
}
.connection-container-accent {
  width: 50.1%;
  background-color: #ffffff;
  height: 100%;
  position: absolute;
}
</style>
