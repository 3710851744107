<template>
  <v-sheet>
    <v-row v-if="connectedToInternet" class="ma-0">
      <v-col cols="12" align="center" justify="center">
        <h1 class="text-h5 text-center">Network Connection Fixed!</h1>
      </v-col>
      <v-col cols="12" justify="center" align="center">
        <v-btn color="primary" class="text-none" @click="closeDialog">
          Continue
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0">
      <v-col cols="12" justify="center" align="center">
        <h1 class="text-h5 text-center justify-center align-center">
          You're Offline!
        </h1>
      </v-col>
      <v-col cols="12" justify="center" align="center">
        <p class="text-center">Please fix your connection to continue</p>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapActions } from "pinia";
import { useDialogStore } from "#src/stores/dialog";

export default {
  props: {
    connectedToInternet: Boolean,
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
  },
};
</script>
