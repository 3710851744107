<template>
  <div id="application-flow">
    <v-fade-transition mode="out-in">
      <component
        :is="props.activeLayoutComponent"
        v-if="ready"
        key="app"
        :show-footer="showFooter"
        :show-header="showHeader"
        @vue:mounted="initializeClientIntegrations"
      >
        <template v-if="!criticalFailureReason" #alert>
          <system-alert />
        </template>
        <template #header>
          <app-header v-bind="$attrs" />
        </template>
        <template #body>
          <transition
            name="fade"
            mode="out-in"
            @enter="stepIsAnimating = false"
            @before-leave="stepIsAnimating = true"
          >
            <component
              :is="getStepComponent[activeStep.step]"
              v-if="activeStep"
              id="router-view"
              ref="form"
              v-bind="$attrs"
              :key="activeStep.step"
              @go-to-active-step="goToActiveStep"
              @hide-footer="temporarilyHideFooter = true"
              @next="next"
              @previous="previous"
              @show-footer="temporarilyHideFooter = false"
              @single-click-condition-complete="next(false)"
              @vue:mounted="componentIsReady"
              @vue:unmounted="componentReady = false"
              @reset-app="resetAppData"
              @go-to-quote-step="goToQuoteStep"
            />
          </transition>
        </template>
        <template v-if="LiveChatContainer && showFooter" #chat>
          <component :is="LiveChatContainer" />
        </template>
        <template #footer>
          <app-footer
            :show-previous="showPrevious"
            :show-skip="activeStep?.skippable"
            :show-next="showNext"
            :loading="loading"
            :next-text="activeStep?.nextText || 'Next'"
            @previous="previous"
            @next="next"
            @skip="next(true)"
            @go-to-quote-step="goToQuoteStep"
            @go-to-name-step="goToNameStep"
          />
        </template>
      </component>
      <div v-else key="loader" style="min-height: 524px">
        <v-row class="ma-0 loader-overlay" justify="center" align="center">
          <v-progress-circular
            indeterminate
            size="32"
            color="basic-icon"
            aria-label="Loading Application"
          />
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>

<script setup>
import AppFooter from '#src/components/AppFooter.vue';
import AppHeader from '#src/components/AppHeader.vue';
import { useOfflineDetector } from '#src/components/shared/OfflineDetector.js';
import SystemAlert from '#src/components/shared/SystemAlert.vue';

import { storeToRefs } from 'pinia';
import { useSnackbarStore } from '#src/stores/snackbar.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { useFlowStore } from '#src/stores/flow.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { useQuotingStore } from '#src/stores/quoting.js';
import {
  CRITICAL_FAILURE_REASONS,
  useStepCriticalFailure,
} from '#src/stores/step-critical-failure.js';

import { trackNewApp, trackExistingApp, attachIntegration as attachGtagIntegration } from '#src/integrations/gtag.mjs';
import { useTheme } from 'vuetify';

import { steps } from '#src/enumerations/step-enumeration.js';
import { getStepConfig } from '#src/enumerations/step-config.js';
import { getStepComponent } from '#src/enumerations/step-components.js';

import { ref, watch, computed, defineAsyncComponent, inject } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

import { parseErrorMessage, undefinedOrNull } from '#src/util/helpers.js';
import { scrollToTop } from '#src/composables/scroll-to.composable.js';

const props = defineProps({
  activeLayoutComponent: {
    type: Object,
    required: true,
  },
});

const pinia = inject('pinia');

const instance = useInstanceSettingsStore(pinia);
const eApp = useEappStore(pinia);
const flow = useFlowStore(pinia);
const stepCriticalFailure = useStepCriticalFailure(pinia);
const quoting = useQuotingStore(pinia);
const snackbar = useSnackbarStore(pinia);

const { activeStep, initialStep } = storeToRefs(flow);
const { criticalFailureReason } = storeToRefs(stepCriticalFailure);

let LiveChatContainer;
if (instance.chat) {
  LiveChatContainer = defineAsyncComponent(
    () => import('#src/components/chat/LiveChatContainer.vue'),
  );
}

const temporarilyHideFooter = ref(false);
const componentReady = ref(false);
const wentPrevious = ref(false);
const stepIsAnimating = ref(false);
// template ref
const form = ref();

const engaged = ref(false);
const appInitialized = ref(false);
let offlineDetector;

const ready = computed(() => {
  if (criticalFailureReason.value) return true;
  return appInitialized.value;
});

const loading = computed(() => {
  return eApp.step_is_saving || stepIsAnimating.value;
});
const showPrevious = computed(() => {
  if (!activeStep.value) return false;
  return Boolean(activeStep.value.previous) || activeStep.value.previous === 0;
});
const showNext = computed(() => {
  if (!activeStep.value) return false;
  if (activeStep.value.controlsNext) return false;
  return Boolean(activeStep.value.next) || activeStep.value.next === 0;
});
const showHeader = computed(() => {
  if (!activeStep.value) return false;
  return !activeStep.value.hidesHeader;
});
const showFooter = computed(() => {
  if (!activeStep.value) return false;
  return !activeStep.value.hidesFooter && !temporarilyHideFooter.value;
});

async function initializeClient(theme) {
  // Prevent dev mode nav issues
  history.replaceState({ step: null }, '');
  offlineDetector = useOfflineDetector(pinia);
  offlineDetector.initialize();
  const { isDarkMode } = storeToRefs(instance);
  watch(isDarkMode, () => {
    theme.global.name.value = instance.isDarkMode ? 'dark' : 'light';
  });

  if (criticalFailureReason.value) {
    await goToStep({ step: steps.CRITICAL_FAILURE, skipSave: true, replace: true });
    return;
  }

  try {
    initializeInteractionEvents();

    appInitialized.value = false;

    if (!eApp.id || !eApp.uuid) await initNewEApp();
    else trackExistingApp();

    let step = flow.step;
    if (!flow.step && flow.step !== 0) step = initialStep.value;
    await goToStep({ skipSave: true, event: 'initialized', step });
    appInitialized.value = true;
  } catch (e) {
    setCriticalFailureReason(CRITICAL_FAILURE_REASONS.FAILED_TO_BOOT);
    throw e;
  }
}

async function resetAppData() {
  appInitialized.value = false;
  eApp.setIds({});

  pinia._s.forEach((store) => {
    if (store.$id === 'instance-settings') return;
    store.$reset();
  });
  await initNewEApp();

  let step = flow.step;
  if (!flow.step && flow.step !== 0) step = initialStep.value;
  await goToStep({ step, skipSave: true, replace: true });
  appInitialized.value = true;
}

async function initNewEApp() {
  instance.setDefaults();
  trackNewApp();

  await quoting.setLocationByIp();

  if (!instance.primaryParams) return;

  const { EappsController } = await import('#src/controllers/eapps.controller.js');
  const eappsController = new EappsController(pinia);
  await eappsController.prefillQuoteAndApply(instance.primaryParams, {
    fromAPI: false,
  });
}

async function initializeClientIntegrations() {
  try {
    attachGtagIntegration(pinia);
  } catch (e) {
    console.error(e);
  }
}

function initializeInteractionEvents() {
  const engagedEvent = () => {
    engaged.value = true;
    window.removeEventListener('click', engagedEvent);
  };
  window.addEventListener('click', engagedEvent);

  const exitEvent = () => {
    if (!engaged.value) return;
    window.removeEventListener('beforeunload', exitEvent);
  };
  window.addEventListener('beforeunload', exitEvent);
}

function setCriticalFailureReason(type) {
  criticalFailureReason.value = type;
  goToStep({ step: steps.CRITICAL_FAILURE, skipSave: true, replace: true });
}

function componentIsReady() {
  componentReady.value = true;

  const onInitialStep = flow.initialStep === activeStep.value.step;
  if (onInitialStep && !instance.activeFirstInput && !hasNavigated) return;
  scrollToTop(pinia, instance.isExternallyEmbedded ? 'instant' : 'smooth');
}

let inNextAction = false;
let inPreviousAction = false;
let hasNavigated = false;

async function next(skip = false) {
  hasNavigated = true;

  if (inNextAction) return;
  inNextAction = true;
  try {
    await nextAction(skip);
  } catch (e) {
    // do nothing
  }
  inNextAction = false;
}

async function nextAction() {
  if (!componentReady.value || loading.value) {
    return;
  }

  wentPrevious.value = false;

  if (form.value.valid && !form.value.valid()) return;

  storeActiveStep();

  const result = await saveActiveStep();
  if (result === false) {
    snackbar.showErrorSnackbar({
      message: 'There was an error with the system please contact support or try again.',
      timeout: 10000,
    });
    return;
  } else if (result === 'validation failure') {
    return;
  }
  temporarilyHideFooter.value = false;

  await goToStep({
    step: activeStep.value.next,
    event: 'next_clicked',
    syncCheck: true,
  });
}

async function goToStep({ syncCheck, replace, skipSave, step = null, event = null }) {
  try {
    if (syncCheck && activeStep.value.step !== history.state.step) return;
    const res = await flow.goToStep({ skipSave, step, event });
    await flow.navToStep({ event, step: res.step, replace });
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function previous() {
  if (inPreviousAction) return;
  inPreviousAction = true;
  try {
    await previousAction();
  } catch (e) {
    // do nothing
  }
  inPreviousAction = false;
}

async function previousAction() {
  hasNavigated = true;
  if (history.state.step === steps.SIGNATURE) {
    return;
  }
  if (!componentReady.value || loading.value) return; //Browser navigational issue here.
  wentPrevious.value = true;
  if (form.value.valid && form.value.valid(true)) {
    storeActiveStep();
    const result = await saveActiveStep();
    if (result === false) {
      snackbar.showErrorSnackbar({
        message: 'There was an error with the system please contact support or try again.',
        timeout: 10000,
      });
      return;
    }
  }

  temporarilyHideFooter.value = false;

  await goToStep({
    syncCheck: true,
    step: activeStep.value.previous,
    event: 'previous_clicked',
    replace: true,
  });
}

function focusStep() {
  if (form.value.focus) form.value.focus();
}

async function saveActiveStep() {
  if (!eApp.id || !form.value.save) return true;
  eApp.step_is_saving = true;
  let error, response;
  try {
    response = await form.value.save();
  } catch (e) {
    error = e;
  } finally {
    eApp.step_is_saving = false;
  }

  if (error) throw error;
  return response;
}

function storeActiveStep() {
  if (form.value.store) form.value.store();
}

async function goToActiveStep(step) {
  await goToStep({
    step,
    event: 'go_to_active_step',
    syncCheck: false,
    skipSave: true,
  });
}

async function goToQuoteStep() {
  await goToStep({ step: steps.QUOTE, event: 'visit_quotes', replace: true, syncCheck: true });
}

async function goToNameStep() {
  await goToStep({ step: steps.NAME, event: 'visit_name', replace: true, syncCheck: true });
}

// Handle Browser Arrows
// We will always replace state unless going next
function handleBrowserNav(to, from, resolve) {
  if (!appInitialized.value) return;
  if (!componentReady.value) return resolve(); //If motion is already in progress

  const comingFrom = getStepConfig(activeStep.value.step, pinia);
  const goingTo = getStepConfig(history.state.step, pinia);

  if (undefinedOrNull(comingFrom) || undefinedOrNull(goingTo)) {
    return resolve(false);
  }

  if (
    temporarilyHideFooter.value &&
    comingFrom.step === steps.SIGNATURE_WAITING &&
    goingTo.step !== steps.UNDERWRITING
  ) {
    return resolve({ state: { step: steps.SIGNATURE_WAITING }, query: from.query });
  }

  const onLogin = comingFrom.step === steps.LOGIN;
  if (onLogin) {
    const goingToStartPage = goingTo.step === flow.initialStep;
    if (!goingToStartPage && !eApp.has_logged_in) {
      return resolve(false);
    }
  }

  //if user utilized browser nav ~
  if (
    comingFrom.step === steps.SIGNATURE &&
    [steps.COMPLETED, steps.QUOTE].includes(goingTo.step) &&
    !wentPrevious.value
  ) {
    return resolve(false);
  }

  if (!componentReady.value || loading.value) return; //Browser navigational issue here.
  if (activeStep.value.step === goingTo.step) return resolve(); // good step

  if (!comingFrom || !goingTo || goingTo.step === comingFrom.step) {
    return resolve(false);
  }

  storeActiveStep();

  if (!wentPrevious.value && comingFrom.previous !== goingTo.step) {
    saveActiveStep();
  }
  goToStep({ step: goingTo.step, replace: true, event: 'browser-nav' });
  return resolve();
}

if (!import.meta.env.SSR) {
  const theme = useTheme();
  initializeClient(theme);
  onBeforeRouteUpdate(handleBrowserNav);
}

defineExpose({ focusStep });
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
}
</style>
