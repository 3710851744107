import { defineAsyncComponent, h } from "vue";
const customSvgNameToComponent = {
  BadDomain: defineAsyncComponent(() => import("#src/components/shared/icons/BadDomain.vue")),
  Bench: defineAsyncComponent(() => import("#src/components/shared/icons/Bench.vue")),
  ColorTree: defineAsyncComponent(() => import("#src/components/shared/icons/ColorTree.vue")),
  ConnectionIssue: defineAsyncComponent(() => import("#src/components/shared/icons/ConnectionIssue.vue")),
  Diamond: defineAsyncComponent(() => import("#src/components/shared/icons/Diamond.vue")),
  Moneybag: defineAsyncComponent(() => import("#src/components/shared/icons/Moneybag.vue")),
  MoneyReturn: defineAsyncComponent(() => import("#src/components/shared/icons/MoneyReturn.vue")),
  Sailboat: defineAsyncComponent(() => import("#src/components/shared/icons/Sailboat.vue")),
  Shield: defineAsyncComponent(() => import("#src/components/shared/icons/Shield.vue")),
  Umbrella: defineAsyncComponent(() => import("#src/components/shared/icons/Umbrella.vue")),
  Money: defineAsyncComponent(() => import("#src/components/shared/icons/Money.vue")),
  QuiltedDiamonds: defineAsyncComponent(() => import("#src/components/shared/icons/QuiltedDiamonds.vue")),
};

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon]),
};

export { customSVGs };
