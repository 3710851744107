export const maritalStatuses = [
  {
    title: "Married",
    value: "Married",
    dataTestid: "marital-status-married",
  },
  {
    title: "Domestic Partner",
    value: "Domestic Partner",
    dataTestid: "marital-status-domestic-partnership",
  },
];
