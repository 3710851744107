<template>
  <v-dialog
    id="dialog-manager"
    v-model="show"
    :class="{ 'widget-dialog': isExternallyEmbedded }"
    :global-top="true"
    :max-width="dialogProps.width || '30rem'"
    :persistent="dialogProps.persistent"
    :fullscreen="dialogProps.fullscreen"
    :scrollable="dialogProps.scrollable"
    @click:outside="handleClickOutside"
  >
    <component
      :is="components[dialogProps.component]"
      v-bind="dialogComponentProps"
      class="sub-dialog"
    />
  </v-dialog>
</template>

<script setup>
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { useDialogStore } from '#src/stores/dialog';

import { storeToRefs } from 'pinia';
import { computed, defineAsyncComponent, inject } from 'vue';

import NetworkFixedDialog from '#src/components/dialogs/NetworkFixedDialog.vue';

const components = {
  NetworkFixedDialog, // we dont want to dynamically load this one as it's needed for the network error
  ComplianceDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/ComplianceDialog.vue'),
  ),
  ConfirmationDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/ConfirmationDialog.vue'),
  ),
  ConfirmUserIsAgentDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/ConfirmUserIsAgentDialog.vue'),
  ),
  EftDialog: defineAsyncComponent(() => import('#src/components/dialogs/EftDialog.vue')),
  InformationCaptureDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/InformationCaptureDialog.vue'),
  ),
  CoverageCalculatorDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/CoverageCalculatorDialog.vue'),
  ),
  EmailFormDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/EmailFormDialog.vue'),
  ),
  EnvelopePreviewDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/EnvelopePreviewDialog.vue'),
  ),
  HelpDialog: defineAsyncComponent(() => import('#src/components/dialogs/HelpDialog.vue')),
  JointInsuredInformationDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/JointInsuredInformationDialog.vue'),
  ),
  LtcBenefitAmounts: defineAsyncComponent(
    () => import('#src/components/dialogs/LtcBenefitAmounts.vue'),
  ),
  MessageDialog: defineAsyncComponent(() => import('#src/components/dialogs/MessageDialog.vue')),
  PolicyTypeExplanation: defineAsyncComponent(
    () => import('#src/components/steps/quote/PolicyTypeExplanation.vue'),
  ),
  PrintSummaryDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/PrintSummaryDialog.vue'),
  ),
  QuoteHintHelper: defineAsyncComponent(
    () => import('#src/components/steps/quote/QuoteHintHelper.vue'),
  ),
  SessionTimedOutDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/SessionTimedOutDialog.vue'),
  ),
  ShareApplicationDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/ShareApplicationDialog.vue'),
  ),
  ShareQuoteAndApplyDialog: defineAsyncComponent(
    () => import('#src/components/dialogs/ShareQuoteAndApplyDialog.vue'),
  ),
};

const pinia = inject('pinia')
const instance = useInstanceSettingsStore(pinia)
const dialog = useDialogStore(pinia);

const { isExternallyEmbedded } = storeToRefs(instance);
const { show, dialogProps } = storeToRefs(dialog);

function handleClickOutside() {
  if (dialogProps.value.persistent) return;
  dialog.closeDialog();
}

const dialogComponentProps = computed(() => {
  //eslint-disable-next-line
  const { width, persistent, fullscreen, scrollable, ...componentProps } = dialogProps.value;
  return componentProps;
});
</script>
