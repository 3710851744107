<template>
  <v-alert
    v-if="systemErrorMessage"
    type="warning"
    class="mb-0"
    variant="outlined"
    tile
    data-testid="system-alert-message"
  >
    {{ systemErrorMessage }}
  </v-alert>
  <v-alert
    v-else-if="displayTestMode"
    color="warning"
    style="z-index: 100"
    variant="outlined"
    density="compact"
    class="mb-0 py-1 text-center w-100 rounded-0"
    data-testid="system-alert-test"
  >
    <v-col class="pa-0">
      This Quote & Apply Widget is in Test Mode
      <br />
      <div class="mb-0 small-text">
        Applications, Exams, and DocuSigns are deleted nightly. You can hide this banner
        <a
          :href="`https://app.back9ins.com/quote-and-apply/${approvedDomainId}`"
          target="_blank"
          class="text-primary clickable"
          >within BOSS.</a
        >
      </div>
    </v-col>
  </v-alert>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { useFlowStore } from '#src/stores/flow.js';
import { computed, inject } from 'vue';
import { useQuotingStore } from '#src/stores/quoting.js';
import { stateToFull } from '#src/util/helpers.js';

const pinia = inject('pinia');
const instance = useInstanceSettingsStore(pinia);
const flow = useFlowStore(pinia);
const quoting = useQuotingStore(pinia);

const {
  id: approvedDomainId,
  agent,
  google_analytics_id,
  google_tag_manager_id,
  errors,
  test,
  hide_test_mode_banner,
  primaryParams,
  metadata,
} = storeToRefs(instance);

const { activeStep } = storeToRefs(flow);
const { applicationState } = storeToRefs(quoting);

const displayTestMode = computed(() => test.value && !hide_test_mode_banner.value);

const licensedErrorMessage = computed(() => {
  if (activeStep.value.hideNoLicenseBanner) return '';

  if (!agent.value.insurance_licenses.length) {
    return `${agent.value.name} does not have any insurance licenses on file.`;
  }

  if (applicationState.value && !agent.value.insurance_licenses.some((l) => l.state === applicationState.value)) {
    return `${agent.value.name} is not licensed in ${stateToFull(applicationState.value)}.`;
  }

  return '';
});

const invalidMetadataErrorMessage = computed(() => {
  if (!metadata.value.valid) return 'Invalid Metadata. Must be valid JSON.';
  return '';
});

const tagManagerErrorMessage = computed(() => {
  if (!errors.value.google_tag_manager_id) return '';
  if (google_analytics_id.value && google_tag_manager_id.value) {
    return `Either the Google Analytics ID ${google_analytics_id.value} or the Google Tag Manager ID ${google_tag_manager_id.value} is invalid. Please confirm you're using the correct IDs`;
  }

  if (google_analytics_id.value) {
    return `The Google Analytics ID ${google_analytics_id.value} is invalid. Please confirm you're using the correct ID.`;
  }

  if (google_tag_manager_id.value) {
    return `The Google Tag Manager ID ${google_tag_manager_id.value} is invalid. Please confirm you're using the correct ID.`;
  }

  return '';
});

const resumeUrlMessage = computed(() => {
  if (!errors.value.resume_url) return;
  return `Invalid resume_url: ${decodeURIComponent(primaryParams.value.resume_url)}`;
});

const systemErrorMessage = computed(() => {
  return (
    licensedErrorMessage.value ||
    invalidMetadataErrorMessage.value ||
    tagManagerErrorMessage.value ||
    resumeUrlMessage.value ||
    ''
  );
});
</script>
