import { defineStore } from "#src/stores/state-wrapper.js"
import { EntityService } from '#src/services/entity.service.js';

import {
  CONTINGENT_BENEFICIARY,
  generateRoleData,
  JOINT_PAYOR,
  OWNER,
  PAYOR,
  PRIMARY_BENEFICIARY,
} from '#src/structures/Role.js';

import { COUNTRIES_WITH_US, STATES } from '#src/data/states-and-countries.js';

import {
  tin as tinValidator,
  email as emailValidator,
  integerToPercent,
  percentToInteger,
  generateUuid,
  isBoolean,
} from '#src/util/helpers.js';

import {
  savablePropertyRequestWrapper,
  useSavableProperty,
} from '#src/composables/savable-property.composable.js';

import {
  validateAccountNumber,
  validateBoolean,
  validateIsBeforeNow,
  validatePhone,
  validateRoutingNumber,
  validateText,
} from '#src/composables/savable-property-validators.mjs';

import {
  ENTITY_BENEFICIARY_RELATIONSHIPS,
  ENTITY_OWNER_RELATIONSHIPS,
} from '#src/data/relationships.js';
import { IRREVOCABLE_OPTIONS } from '#src/composables/entity-form-property.composable.js';
import { getPayorRoleForInsured } from '#src/stores/insured.js';

export const BUSINESS_TAX_TYPES = [
  'General Partnership',
  'LLC',
  'LLP',
  'C Corporation',
  'S Corporation',
].map((v) => ({ title: v, value: v }));

export const entityKeyGen = (id) => `parties-entity-${id}`;

export function useEntityStore(partyKey, pinia, hot) {
  const entityService = new EntityService(pinia);
  return defineStore(partyKey, {
    state: () => ({
      address_id: null,
      id: null,
      party_id: null,
      responsible_individual_id: null,
      responsible_party_id: null,

      account_last_4: null,
      routing_last_4: null,

      roles: {
        [PRIMARY_BENEFICIARY]: null,
        [CONTINGENT_BENEFICIARY]: null,
        [OWNER]: null,
        [PAYOR]: null,
        [JOINT_PAYOR]: null,
      },

      role_for_id: {
        [PRIMARY_BENEFICIARY]: null,
        [CONTINGENT_BENEFICIARY]: null,
        [PAYOR]: null,
        [JOINT_PAYOR]: null,
        [OWNER]: null,
      },

      draft_day_of_month: useSavableProperty({
        requestMap: 'draft_day_of_month',
        group: 'eft',
        rules: {
          inRange: {
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return store.draft_day_of_month.model >= 1 && store.draft_day_of_month.model <= 28;
            },
            message: 'Must be between 1 and 28',
          },
        },
      }),
      irrevocable: useSavableProperty({
        requestMap: 'irrevocable',
        group: 'root',
        rules: {
          isValid: {
            message: 'Required',
            v: () => {
              const store = useEntityStore(partyKey, pinia);

              return IRREVOCABLE_OPTIONS.some((option) => option.value === store.irrevocable.model);
            },
          },
        },
      }),
      tax_type: useSavableProperty({
        requestMap: 'tax_type',
        group: 'root',
        rules: {
          isValid: {
            message: 'Required',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return BUSINESS_TAX_TYPES.some((option) => option.value === store.tax_type.model);
            },
          },
        },
      }),
      street_address: useSavableProperty({
        requestMap: 'street_address',
        group: 'address',
        rules: {
          minLength: validateText(() => useEntityStore(partyKey, pinia).street_address.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      city: useSavableProperty({
        requestMap: 'city',
        group: 'address',
        rules: {
          minLength: validateText(() => useEntityStore(partyKey, pinia).city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      state: useSavableProperty({
        requestMap: 'state',
        group: 'address',
        rules: {
          minLength: {
            message: 'Must be a valid state',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return STATES.some(({ value }) => value === store.state.model);
            },
          },
        },
      }),
      zip: useSavableProperty({
        requestMap: 'zip',
        group: 'address',
        rules: {
          minLength: validateText(() => useEntityStore(partyKey, pinia).zip.model, {
            exactLength: 5,
          }),
        },
      }),
      country: useSavableProperty({
        requestMap: 'country',
        group: 'address',
        rules: {
          minLength: {
            message: 'Must be a valid country',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return COUNTRIES_WITH_US.some(({ value }) => value === store.country.model);
            },
          },
        },
      }),
      email: useSavableProperty({
        requestMap: 'email',
        group: 'root',
        rules: {
          isValid: {
            message: 'Must be a valid email',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return store.email.requestMeta.externallyValid === true;
            },
          },
          regexMatch: {
            message: 'Must be a valid email',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return emailValidator(store.email.model);
            },
          },
        },
      }),
      formation_date: useSavableProperty({
        requestMap: 'formation_date',
        group: 'root',
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(
            () => useEntityStore(partyKey, pinia).formation_date.model,
          ),
        },
      }),
      name: useSavableProperty({
        requestMap: 'name',
        group: 'root',
        rules: {
          validLength: validateText(() => useEntityStore(partyKey, pinia).name.model, {
            minLength: 1,
            maxLength: 100,
          }),
        },
      }),
      phone_work: useSavableProperty({
        requestMap: 'phone_work',
        group: 'root',
        rules: {
          validNumber: validatePhone(() => useEntityStore(partyKey, pinia).phone_work.model),
        },
      }),
      signature_city: useSavableProperty({
        requestMap: 'signature_city',
        group: 'root',
        rules: {
          minLength: validateText(() => useEntityStore(partyKey, pinia).signature_city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      tin: useSavableProperty({
        requestMap: 'tin',
        group: 'root',
        rules: {
          validSsn: {
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return tinValidator(store.tin.model);
            },
            message: 'Must be a valid TIN',
          },
        },
      }),
      beneficiary_amount: useSavableProperty({
        requestMap: 'beneficiary_amount',
        group: 'beneficiary',
        requestFormatter: (v) => +integerToPercent(v),
        rules: {
          inRange: {
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return store.beneficiary_amount.model >= 1 && store.beneficiary_amount.model <= 100;
            },
            message: 'Must be between 1 and 100',
          },
        },
      }),
      relationship: useSavableProperty({
        requestMap: 'relationship',
        group: 'role',
        rules: {
          inList: {
            message: 'Must be in list',
            v: () => {
              const store = useEntityStore(partyKey, pinia);

              let options = ENTITY_BENEFICIARY_RELATIONSHIPS;
              if (store.roles[OWNER]) options = ENTITY_OWNER_RELATIONSHIPS;
              return options.includes(store.relationship.model);
            },
          },
        },
      }),
      insured_is_signer: useSavableProperty({
        rules: {
          isTrueOrFalse: validateBoolean(
            () => useEntityStore(partyKey, pinia).insured_is_signer.model,
          ),
        },
      }),
      responsible_individual_email: useSavableProperty({
        requestMap: 'email',
        group: 'responsibleIndividual',
        rules: {
          // BasicEmailInput triggers this on its own. look into refactoring if possible
          isValid: {
            message: 'Must be a valid email',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return store.responsible_individual_email.requestMeta.externallyValid === true;
            },
          },
          regexMatch: {
            message: 'Must be a valid email',
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return emailValidator(store.responsible_individual_email.model);
            },
          },
        },
      }),
      responsible_individual_first_name: useSavableProperty({
        requestMap: 'first_name',
        group: 'responsibleIndividual',
        rules: {
          validLength: validateText(
            () => useEntityStore(partyKey, pinia).responsible_individual_first_name.model,
            { minLength: 1, maxLength: 50 },
          ),
        },
      }),
      responsible_individual_last_name: useSavableProperty({
        requestMap: 'last_name',
        group: 'responsibleIndividual',
        rules: {
          validLength: validateText(
            () => useEntityStore(partyKey, pinia).responsible_individual_last_name.model,
            { minLength: 1, maxLength: 50 },
          ),
        },
      }),
      responsible_party_relationship: useSavableProperty({
        requestMap: 'relationship',
        group: 'responsibleParty',
        rules: {
          validLength: validateText(() => useEntityStore(partyKey, pinia).relationship.model, {
            minLength: 1,
            maxLength: 50,
          }),
        },
      }),
      responsible_party_sole_signer: useSavableProperty({
        requestMap: 'sole_signer',
        group: 'responsibleParty',
        rules: {
          mustBeTrue: {
            v: () => {
              const store = useEntityStore(partyKey, pinia);
              return store.responsible_party_sole_signer.model === true;
            },
            message: 'You must agree with this to proceed',
          },
        },
      }),
      account_number: useSavableProperty({
        group: 'eftNumbers',
        requestMap: 'account_number',
        rules: {
          validLength: validateAccountNumber(
            () => useEntityStore(partyKey, pinia).account_number.model,
          ),
        },
      }),
      routing_number: useSavableProperty({
        group: 'eftNumbers',
        requestMap: 'routing_number',
        rules: {
          validLength: validateRoutingNumber(
            () => useEntityStore(partyKey, pinia).routing_number.model,
          ),
        },
      }),
    }),
    getters: {
      /** Helpers */
      displayName: (s) => s.name.asString(),
      beneficiaryAmount: (s) => s.beneficiary_amount.asString(),
      generalType: () => 'entity',
      type: (s) => (isBoolean(s.irrevocable.model) ? 'trust' : 'business'),
      isTrust: (s) => s.type === 'trust',
      isBusiness: (s) => s.type === 'business',
      partyKey: () => partyKey,
    },
    actions: {
      initializeFromEapp(rawModel) {
        let responsibleParty = {};
        if (rawModel?.responsible_parties?.length) {
          responsibleParty = rawModel.responsible_parties[0];
        }

        let responsibleIndividual = {};
        if (responsibleParty.id) {
          responsibleIndividual = responsibleParty.responsible_individual;
        } else if (rawModel?.responsible_individuals?.length) {
          responsibleIndividual = rawModel?.responsible_individuals[0];
        }

        const { roles, beneficiary_amount, relationship, role_for_id } = new generateRoleData(
          rawModel.application_roles,
        );

        let address = rawModel?.address;
        if (rawModel?.addresses?.length) address = rawModel.addresses[0];
        address = address || {};

        this.id = entityKeyGen(rawModel.id);
        this.party_id = rawModel.id;
        this.responsible_party_id = responsibleParty?.id;
        this.responsible_individual_id = responsibleIndividual?.id;

        this.address_id = address.id;
        Object.keys(this.roles).forEach((r) => (this.roles[r] = roles[r]));
        Object.keys(this.role_for_id).forEach((r) => (this.role_for_id[r] = role_for_id[r]));

        if (rawModel.name) this.name.load(rawModel.name);
        if (rawModel.tin) this.tin.load(rawModel.tin);
        if (rawModel.email) this.email.load(rawModel.email);
        if (rawModel.draft_day_of_month) this.draft_day_of_month.load(rawModel.draft_day_of_month);

        this.irrevocable.load(rawModel.irrevocable);
        if (rawModel.phone_work) this.phone_work.load(rawModel.phone_work);
        if (rawModel.formation_date) this.formation_date.load(rawModel.formation_date);
        this.responsible_party_relationship.load(responsibleParty?.relationship);
        this.responsible_party_sole_signer.load(responsibleParty?.sole_signer);
        this.responsible_individual_first_name.load(responsibleIndividual?.first_name);
        this.responsible_individual_last_name.load(responsibleIndividual?.last_name);
        if (responsibleIndividual?.email) {
          this.responsible_individual_email.load(responsibleIndividual.email);
        }
        this.signature_city.load(rawModel.signature_city);
        this.beneficiary_amount.load(percentToInteger(beneficiary_amount));
        this.relationship.load(relationship);

        this.street_address.load(address?.street_address || '');
        this.city.load(address?.city || '');
        this.state.load(address?.state || '');
        this.zip.load(address?.zip || '');
        this.country.load(address?.country || '');

        if (rawModel.efts?.length) {
          const { account_number_last_4, routing_last_4 } = rawModel.efts[0];
          this.account_last_4 = account_number_last_4;
          this.routing_last_4 = routing_last_4;
        }
      },
      async createIndividualAndOrParty({ individualId }) {
        const entity = await entityService.updateEntity(this.party_id, {
          responsible_parties_attributes: [
            {
              id: this.responsible_party_id,
              responsible_individual_attributes: { id: individualId },
            },
          ],
        });

        const responsible_party = entity.responsible_parties[0];
        const responsible_individual = entity.responsible_individuals[0];

        this.responsible_party_id = responsible_party.id;
        this.responsible_party_relationship.load(responsible_party.relationship);
        this.responsible_party_sole_signer.load(responsible_party.sole_signer);

        this.responsible_individual_id = responsible_individual.id;
        this.responsible_individual_email.load(responsible_individual.email);
        this.responsible_individual_first_name.load(responsible_individual.first_name);
        this.responsible_individual_last_name.load(responsible_individual.last_name);
      },
      savablePropertyWrapper(func, { attributes, body, uuid }) {
        const composableAttributes = attributes.map((a) => this[a]);
        return savablePropertyRequestWrapper(func, { composableAttributes, body, uuid });
      },
      saveAttributes(attributes = []) {
        if (!attributes.length) return;

        const reqMap = {};
        const reqAttrs = {};

        attributes.forEach((a) => {
          const key = this[a].requestMeta.requestMap;
          const group = this[a].requestMeta.group;
          const value = this[a].requestMeta.format();

          if (!reqAttrs[group]) {
            reqMap[group] = {};
            reqAttrs[group] = [];
          }
          reqMap[group][key] = value;
          reqAttrs[group].push(a);
        });

        const { eft: eftReqGroup, ...baseReqGroups } = reqMap;
        const { eft: eftAttrs, ...baseAttrs } = reqAttrs;

        const promises = [];
        if (eftAttrs) promises.push(this.saveEftData(eftAttrs, eftReqGroup));
        if (baseAttrs) {
          const attrs = Object.values(baseAttrs).reduce((acc, v) => [...acc, ...v], []);
          if (attrs.length) promises.push(this.saveBaseAttributes(attrs, baseReqGroups));
        }

        return Promise.all(promises);
      },
      async saveBaseAttributes(
        attributes,
        { root, address, beneficiary, role, responsibleParty, responsibleIndividual },
      ) {
        let body = {};

        if (root) body = { ...root };

        if (address) {
          const addressAttributes = { ...address };
          if (this.address_id) addressAttributes.id = this.address_id;
          else addressAttributes.address_type = 'business';
          body.addresses_attributes = [addressAttributes];
        }

        if (role || beneficiary) {
          body.application_roles_attributes = [];

          Object.keys(this.roles).forEach((r) => {
            if (!this.roles[r]) return;

            let roleReq = {};
            if (role) roleReq = { ...roleReq, ...role };

            const isBeneRole = [PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(r);
            if (isBeneRole && beneficiary) roleReq = { ...roleReq, ...beneficiary };

            body.application_roles_attributes.push({
              id: this.roles[r],
              ...roleReq,
            });
          });
        }

        if (responsibleIndividual || responsibleParty) {
          let responsiblePartyReqObj = { id: this.responsible_party_id };

          if (responsibleParty) {
            responsiblePartyReqObj = { ...responsiblePartyReqObj, ...responsibleParty };
          }
          if (responsibleIndividual) {
            responsiblePartyReqObj.responsible_individual_attributes = {
              id: this.responsible_individual_id,
              ...responsibleIndividual,
            };
          }

          body.responsible_parties_attributes = [responsiblePartyReqObj];
        }

        const func = () => entityService.updateEntity(this.party_id, body);
        const rawParty = await this.savablePropertyWrapper(func, {
          attributes,
          body,
          uuid: generateUuid(),
        });

        if (rawParty?.responsible_individuals?.length) {
          this.responsible_individual_id = rawParty.responsible_individuals[0].id;
        }
        if (rawParty?.responsible_parties?.length) {
          this.responsible_party_id = rawParty.responsible_parties[0].id;
        }

        if (rawParty?.addresses?.length) {
          const businessAddress = rawParty.addresses.find(
            ({ address_type }) => address_type === 'business',
          );
          if (businessAddress) this.address_id = businessAddress.id;
        }
      },
      async saveEftData(attributes, eftBody) {
        const func = () => entityService.updateEft(this.party_id, eftBody);
        return this.savablePropertyWrapper(func, {
          attributes,
          body: eftBody,
          uuid: generateUuid(),
        });
      },
      async saveEftAccountNumbers() {
        const body = {
          routing_number: this.routing_number.model,
          account_number: this.account_number.model,
        };

        const func = () => entityService.updateEft(this.party_id, body);
        const attributes = ['account_number', 'routing_number'];

        const res = await this.savablePropertyWrapper(func, {
          attributes,
          body,
          uuid: generateUuid(),
        });

        const { account_last_4, routing_last_4 } = res;
        this.account_last_4 = account_last_4;
        this.routing_last_4 = routing_last_4;
      },
      async deleteRole(role) {
        const body = {
          application_roles_attributes: [{ id: this.roles[role], _destroy: true }],
        };
        await entityService.updateEntity(this.party_id, body);
        this.roles[role] = null;
        if (role in this.role_for_id) this.role_for_id[role] = null;
      },
      async deleteParty() {
        await entityService.deleteEntity(this.party_id);
        this.$dispose();
      },
      async addRole(body) {
        return await entityService.updateEntity(this.party_id, body);
      },
      setCreatedRoleData(role, applicationRoles) {
        const createdRole = applicationRoles.find((r) => r.role === role);
        this.roles[role] = createdRole.id;
        if (role in this.role_for_id) this.role_for_id[role] = createdRole.insured_id;

        if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
          this.beneficiary_amount.load(percentToInteger(createdRole.beneficiary_amount));
          this.relationship.load(createdRole.relationship);
        }
      },
      async addBeneficiaryRole({ beneficiary_amount, role, insured_id }) {
        const body = {
          application_roles_attributes: [
            {
              role,
              beneficiary_amount: integerToPercent(beneficiary_amount),
              insured_id,
              relationship: this.relationship.model,
            },
          ],
        };
        const res = await this.addRole(body);
        this.setCreatedRoleData(role, res.application_roles);
      },
      async addPayorRole({ insured_id }) {
        let insuredId = insured_id;
        if (!Array.isArray(insuredId)) insuredId = [insuredId];

        const roles = [];
        const application_roles_attributes = [];
        insuredId.forEach((i) => {
          const role = getPayorRoleForInsured(i, pinia);
          roles.push(role);
          application_roles_attributes.push({ role, insured_id: i });
        });
        const body = { application_roles_attributes };

        const res = await this.addRole(body);
        roles.forEach((r) => {
          this.setCreatedRoleData(r, res.application_roles);
        });
      },
    },
  })(pinia, hot);
}
