import { defineStore } from "#src/stores/state-wrapper.js"
import { CasesService } from '#src/services/cases.service.js';
import { steps } from '#src/enumerations/step-enumeration.js';

import { useFlowStore } from '#src/stores/flow.js';

export const CASE_KEY = 'case';
export const useCaseStore = (pinia, hot) =>
  defineStore(CASE_KEY, {
    state: () => ({
      id: null,
      uuid: null,
      eapp_id: null,
      status: null,
      premium: null,
      mode: null,
      policy_number: null,
      state: null,
      applied_rating: null,
      approved_rating: null,
      applied_premium: null,
      approved_premium: null,
      line: null,
      face_amount: null,
      pool_of_money: null,
      product: {
        name: null,
      },
      carrier: {
        avatar_url: null,
        name: null,
      },
      primary_agent: {
        name: null,
        phone_work: null,
        email: null,
        type: null,
        avatar_url: null,
        scheduling_link: null,
      },
      inforce_date: null,
      sharable_apply_link: null,
      requirements: [],
      contract_parties: [],
    }),
    actions: {
      setIds({ id, uuid }) {
        this.id = id;
        this.uuid = uuid;
      },
      async loginToCase(birthdate) {
        const casesService = new CasesService(pinia);
        const caseData = await casesService.getCase({
          id: this.id,
          uuid: this.uuid,
          birthdate,
        });

        this.status = caseData.status;
        this.premium = caseData.premium;
        this.mode = caseData.mode;
        this.policy_number = caseData.policy_number;
        this.state = caseData.state;
        this.applied_rating = caseData.applied_rating;
        this.approved_rating = caseData.approved_rating;
        this.applied_premium = caseData.applied_premium;
        this.approved_premium = caseData.approved_premium;
        this.line = caseData.line;
        this.sharable_apply_link = caseData.sharable_apply_link;
        this.eapp_id = caseData.eapp?.id;

        this.face_amount = caseData.face_amount;
        this.pool_of_money = caseData.pool_of_money;

        if (caseData.product) {
          const { name } = caseData.product;
          this.product.name = name;
        }

        if (caseData.carrier) {
          const { avatar_url, name } = caseData.carrier;
          this.carrier.avatar_url = avatar_url;
          this.carrier.name = name;
        }

        if (caseData.primary_agent) {
          const { name, phone_work, email, type, avatar_url, scheduling_link } =
            caseData.primary_agent;

          this.primary_agent.name = name;
          this.primary_agent.phone_work = phone_work;
          this.primary_agent.email = email;
          this.primary_agent.type = type;
          this.primary_agent.avatar_url = avatar_url;
          if (scheduling_link) {
            const link = scheduling_link.replace('https://', '').replace('http://', '');
            this.primary_agent.scheduling_link = `https://${link}`;
          }
        }

        if (Array.isArray(caseData.timeline)) {
          const inforceDate = caseData.timeline.find((v) => v?.key === 'inforce_date');
          this.inforce_date = inforceDate?.date;
        }

        if (Array.isArray(caseData.requirements)) {
          this.requirements = caseData.requirements.map((r) => r?.description).filter(Boolean);
        }

        if (Array.isArray(caseData.contract_parties)) {
          this.contract_parties = caseData.contract_parties.map((party) => {
            return {
              name: party.name,
              roles: party.roles.map((r) => ({
                role: r.role,
                beneficiary_amount: r.beneficiary_amount,
              })),
            };
          });
        }

        const flow = useFlowStore(pinia);
        flow.navToStep({ step: steps.COMPLETED, replace: true });
      },
    },
  })(pinia, hot);
