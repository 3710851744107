import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"

import { useEappStore } from '#src/stores/electronic-application.js';
import { useStepQuoteStore } from '#src/stores/step-quote.js';
import { useStepLifePriorityStore } from '#src/stores/step-life-priority.js';
import { useStepBirthdateStore } from '#src/stores/step-birthdate.js';

export const useStepBenefitEstimationStore = (pinia, hot) =>
  defineStore('step-benefit-estimation', {
    getters: {
      step: () => steps.BENEFIT_ESTIMATION,
      routeName: (s) => stepToRouteName[s.step],
      next: () => {
        const quote = useStepQuoteStore(pinia);
        return quote.step;
      },
      previous() {
        const birthdate = useStepBirthdateStore(pinia);
        const previousIsBirthdate = birthdate.next === this.step;
        if (previousIsBirthdate) return birthdate.step;

        const priority = useStepLifePriorityStore(pinia);
        return priority.step;
      },
      inactive() {
        const birthdate = useStepBirthdateStore(pinia);
        if (birthdate.next === this.step) return false;
        const eAppStore = useEappStore(pinia);
        return !['growth', 'supplement_retirement'].includes(eAppStore.assistance.topPriority);
      },
    },
  })(pinia, hot);
