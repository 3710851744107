import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

let hasLoaded;
export function attachIntegration(pinia) {
  if (window.dataLayer) handleGtagLoad(pinia);
  else document.getElementById('gtag').addEventListener('load', () => handleGtagLoad(pinia));
}

function handleGtagLoad(pinia) {
  const instance = useInstanceSettingsStore(pinia);
  hasLoaded = true;
  window.addEventListener('click', trackUserHasInteracted, { once: true });

  const tags = [];
  if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID) {
    tags.push({ id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID, isAnalytics: false });
  }
  if (instance.google_tag_manager_id) {
    tags.push({ id: instance.google_tag_manager_id, isAnalytics: false });
  }
  if (instance.google_analytics_id) {
    tags.push({ id: instance.google_analytics_id, isAnalytics: true });
  }

  initializeDataLayer(import.meta.env.VITE_ANALYTICS_ID, tags);
}

function gtag() {
  try {
    window.dataLayer.push(arguments);
  } catch (e) {
    // do nothing
  }
}

export function initializeDataLayer(defaultTagId, extraTags) {
  if (!hasLoaded) return;

  window.dataLayer = window.dataLayer || [];

  gtag('js', new Date());
  gtag('config', defaultTagId, {
    send_page_view: false,
    cookie_flags: 'samesite=none;secure',
    groups: 'analytics',
  });

  // Send page view must be false due to this being an SPA
  extraTags.forEach((tag) => {
    gtag('config', tag.id, {
      send_page_view: false,
      cookie_flags: 'samesite=none;secure',
      groups: tag.isAnalytics ? 'analytics' : 'tagmanager',
    });
  });
}

export function trackPageChange({ page_title, page_location }) {
  if (!hasLoaded) return;
  gtag('event', 'page_view', {
    page_title,
    page_location,
    send_to: 'analytics',
  });
}

export function trackNewApp() {
  if (!hasLoaded) return;
  gtag('event', 'new_app', { send_to: 'analytics' });
}

export function trackExistingApp() {
  if (!hasLoaded) return;
  gtag('event', 'existing_app', { send_to: 'analytics' });
}

export function trackAppCompleted() {
  if (!hasLoaded) return;
  gtag('event', 'completed_app', { send_to: 'analytics' });
}

export function trackUserHasInteracted() {
  if (!hasLoaded) return;
  gtag('event', 'user_has_interacted', { send_to: 'analytics' });
}

export function trackUserIsSharing() {
  if (!hasLoaded) return;
  gtag('event', 'user_is_sharing', { send_to: 'analytics' });
}

export function trackRepeatCustomer() {
  if (!hasLoaded) return;
  gtag('event', 'user_is_repeat_customer', { send_to: 'analytics' });
}

export function trackUserCheckingProgress() {
  if (!hasLoaded) return;
  gtag('event', 'user_checking_application_progress', { send_to: 'analytics' });
}
